import * as _zhCN4 from "element-ui/lib/locale/lang/zh-CN";
var _zhCN3 = "default" in _zhCN4 ? _zhCN4.default : _zhCN4;
import * as _vue4 from "vue";
var _vue3 = "default" in _vue4 ? _vue4.default : _vue4;
import * as _deepmerge4 from "deepmerge";
var _deepmerge3 = "default" in _deepmerge4 ? _deepmerge4.default : _deepmerge4;
import _format3 from "./format";
var exports = {};
exports.__esModule = true;
exports.i18n = exports.use = exports.t = undefined;
var _zhCN = _zhCN3;
var _zhCN2 = _interopRequireDefault(_zhCN);
var _vue = _vue3;
var _vue2 = _interopRequireDefault(_vue);
var _deepmerge = _deepmerge3;
var _deepmerge2 = _interopRequireDefault(_deepmerge);
var _format = _format3;
var _format2 = _interopRequireDefault(_format);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var format = (0, _format2.default)(_vue2.default);
var lang = _zhCN2.default;
var merged = false;
var i18nHandler = function i18nHandler() {
  var vuei18n = Object.getPrototypeOf(this || _vue2.default).$t;
  if (typeof vuei18n === "function" && !!_vue2.default.locale) {
    if (!merged) {
      merged = true;
      _vue2.default.locale(_vue2.default.config.lang, (0, _deepmerge2.default)(lang, _vue2.default.locale(_vue2.default.config.lang) || {}, {
        clone: true
      }));
    }
    return vuei18n.apply(this, arguments);
  }
};
var t = exports.t = function t(path, options) {
  var value = i18nHandler.apply(this, arguments);
  if (value !== null && value !== undefined) return value;
  var array = path.split(".");
  var current = lang;
  for (var i = 0, j = array.length; i < j; i++) {
    var property = array[i];
    value = current[property];
    if (i === j - 1) return format(value, options);
    if (!value) return "";
    current = value;
  }
  return "";
};
var use = exports.use = function use(l) {
  lang = l || lang;
};
var i18n = exports.i18n = function i18n(fn) {
  i18nHandler = fn || i18nHandler;
};
exports.default = {
  use: use,
  t: t,
  i18n: i18n
};
export default exports;
export const __esModule = exports.__esModule;
const _i18n = exports.i18n,
  _use = exports.use,
  _t = exports.t;
export { _i18n as i18n, _use as use, _t as t };